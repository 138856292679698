import rahul from '../../../images//mlp-part-time/alumni/rahul.webp';
import ruchi from '../../../images//mlp-part-time/alumni/ruchi.webp';
import vikas from '../../../images/mlp-part-time/alumni/vikas.webp';
import vishal from "../../../images/mlp/alumnies/vishal.webp";

export const studentData = [
  {
    image_url:
      ruchi,
    name: "Ruchi Jaiswal",
    college: "MBA, University of Mumbai’21",
    placed: "Performics",
    description:
      "I loved the most was that people were encouraged to ask the silliest and the stupidest of the questions without making us feel bad.",
  },
  // {
  //   image_url:
  //     "https://kfwebassets.s3.ap-south-1.amazonaws.com/MLP_Form/kalpesh.png",
  //   name: "Kalpesh Nikhare",
  //   college: "B.E. Mechanical, Mumbai University’18",
  //   placed: "Sif Homes",
  //   description:
  //     "Getting into marketing is tough - its either MBA or nothing at all. But then I joined Kraftshala and here I am today!",
  // },
  // {
  //   image_url:
  //     "https://kfwebassets.s3.ap-south-1.amazonaws.com/MLP_Form/ayush.png",
  //   name: "Ayush Taragi",
  //   college: "MBA, College of Agribusiness Management’19",
  //   placed: "Splash Learn",
  //   description:
  //     "The kind of handholding support Kraftshala gives you, you just move on with them with the flow and they will take you across the ocean.",
  // },
  {
    image_url:
      rahul,
    name: "Rahul Nagpal",
    college: "IHM Chandigarh’17",
    placed: "Performics",
    description:
      "Kraftshala offers a very nice balance of practical learning, internship and placement as compared to just an internship or an MBA.",
  },
  // {
  //   image_url:
  //     "https://kfwebassets.s3.ap-south-1.amazonaws.com/MLP_Form/surbhi.png",
  //   name: "Surbhi Jain",
  //   college: "Symbiosis College of Art and Commerce’17",
  //   placed: "Sif Homes",
  //   description:
  //     "The three months learning is equal to the three years of my schooling. That much I have learned!",
  // },
  {
    image_url:  
    vishal,
    name: "Vishal Jaiprakash Nair",
    college: "SRM University’19",
    placed: "Team Pumpkin",
    description:
      "I haven't seen learning experiences like Kraftshala where everyone is so ready to help you at whatever point it is, and ready to explain things to you no matter how many times.",
  },
  {
    image_url:
      vikas,
    name: "Vikash Kumar Sahu",
    college: "BTech 2018",
    placed: "Team Pumpkin",
    description:
      "If you have a passion for marketing and need a streamlined industrial training, then there is no better place than Kraftshala.",
  },
];
